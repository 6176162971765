import type {IEtaInfo} from '~/types';

/** Returns the user-friendly message of the Estimated Time of Arrival (ETA)
 * @example - "Recibelo entre el 18 y 24 de noviembre"
 */
export const getEtaMessage = (eta: IEtaInfo | { messages: IEtaInfo['messages'] } | undefined | null) => {
    if (eta && eta.messages) {
        const message = eta.messages.find(({type}) => type === 'inline')?.lang;
        if (message) {
            const messageFormatted: string[] = [];
            const words = message.split(' ');
            words.forEach((word: string) => {
                if (!regularWords.has(word.toLowerCase())) {
                    messageFormatted.push('*' + word + '*');
                } else {
                    messageFormatted.push(word);
                }
            });
            return messageFormatted.join(' ');
        }
        return undefined;
    }
    return undefined;
};

export const getEtaMessageModal = (eta: IEtaInfo | undefined | null) => {
    if (eta && eta.messages) {
        const message = eta.messages.find(({ident}) => ident === 'eta_days')?.lang;
        if (message) {
            return message.replaceAll('<strong>', '*').replaceAll('</strong>', '*');
        }
        return undefined;
    }
    return undefined;
};

export const getEtaMessageModalAf = (eta: IEtaInfo | undefined | null) => {
    if (eta && eta.messages) {
        const message = eta.messages.find(({ident}) => ident === 'eta_offer')?.lang;
        if (message) {
            let msg = `el ${message.split('(')[0].trim()}*.`.toLowerCase();
            const position = msg.search(/\d/);
            if (position !== -1) {
                msg = msg.slice(0, position) + '*' + msg.slice(position);
            }
            return msg;
        }
        return undefined;
    }
    return undefined;
};

const getMessageByIdent = (
    eta: IEtaInfo,
    ident: string,
): string | undefined => {
    return eta.messages.find((m) => m.ident === ident)?.lang;
};

export const getEtaMessageAlertAf = (
    eta: IEtaInfo | undefined | null,
) => {
    if (eta && eta.messages) {
        return getMessageByIdent(eta, 'eta_ocurre') || getMessageByIdent(eta, 'eta_only_days');
    }
    return undefined;
};

const regularWords = new Set([
    'recíbelo',
    'entre',
    'el',
    'y',
]);
